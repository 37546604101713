import * as React from 'react';
import {
  SingleDayAvailability,
  useBookingFlowManager,
} from 'src/context/bookingFlow';
import TimeCarousel from './TimeCarousel';
import TimeSelect from './TimeSelect';
import Nudge from './Nudge';
import styled from 'styled-components';

const TimeStepPaddingX = '1.25rem';
const NudgeWrapper = styled.div`
  padding: 0 ${TimeStepPaddingX};
`;

const Times = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  overflow-x: auto;
  padding: 0.5rem ${TimeStepPaddingX} 1rem ${TimeStepPaddingX};

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
`;

const ScrollMarker = styled.div`
  position: absolute;
  left: -1.25rem;
`;

const TimeStepWrapper = styled.div`
  position: relative;
`;

const getReferenceDate = (time: string) =>
  new Date(`2000-01-01T${time}:00.000Z`);

const TimeSlots: React.FC<{
  d: SingleDayAvailability;
  isDesktopOrTablet: boolean;
}> = ({ d, isDesktopOrTablet }) => {
  const { send, isReFetching, selectedTimeSlot, searchData } =
    useBookingFlowManager();

  const times = React.useMemo(
    () => d.availability.find(Boolean)?.times?.filter((t) => t.type === 'book'),
    [d]
  );

  // We need to find the timeslot that's closest to `searchData?.When.time` and
  // make sure that this is in view.
  const closestTimeslotRef = React.useRef<HTMLDivElement>(null);

  const timeSlots = React.useMemo(() => {
    const referenceTime = getReferenceDate(
      searchData?.When.time || '00:00'
    ).getTime();

    const closestSlowPriorToSearchTimeIndex =
      referenceTime &&
      times?.findLastIndex(
        (t) => getReferenceDate(t.time).getTime() <= referenceTime
      );

    return {
      startingPosition:
        closestSlowPriorToSearchTimeIndex &&
        closestSlowPriorToSearchTimeIndex >= 0
          ? closestSlowPriorToSearchTimeIndex
          : null,

      times: times?.map((t, i) => ({
        ...t,
        shouldFocus: i === closestSlowPriorToSearchTimeIndex,
      })),
    };
  }, [searchData?.When.time, times]);

  const timeElements = React.useMemo(
    () =>
      timeSlots.times?.map((t) => {
        const isSelected = selectedTimeSlot?.timeIso === t.timeIso;
        return (
          <TimeStepWrapper key={`${d.requestedDay}-${t.timeIso}`}>
            <ScrollMarker
              ref={t.shouldFocus ? closestTimeslotRef : undefined}
            />
            <TimeSelect
              time={t.time}
              price={t.charge}
              selected={isSelected}
              disabled={isReFetching}
              onClick={() =>
                send({
                  type: 'onTimeSlotSelected',
                  timeSlot: t,
                  bookingFlowType: d.bookingFlowType,
                })
              }
            />
          </TimeStepWrapper>
        );
      }) ?? [],
    [
      timeSlots,
      selectedTimeSlot?.timeIso,
      d.requestedDay,
      d.bookingFlowType,
      isReFetching,
      send,
    ]
  );

  React.useEffect(() => {
    if (!isDesktopOrTablet) {
      closestTimeslotRef.current?.scrollIntoView({
        block: 'nearest',
        inline: 'start',
      });
    }
  }, [isDesktopOrTablet]);

  if (!times || !times.length) {
    return (
      <NudgeWrapper>
        <Nudge text="Sorry, there is no availability on this day." />
      </NudgeWrapper>
    );
  }

  return isDesktopOrTablet ? (
    <TimeCarousel startingPosition={timeSlots.startingPosition}>
      {timeElements}
    </TimeCarousel>
  ) : (
    <Times>{timeElements}</Times>
  );
};

export default TimeSlots;
